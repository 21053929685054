import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core"
import {
    EvaluacionMultipleInstancias,
    EvaluacionMultipleInstancia,
    EvaluacionInstancias
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasById } from "@puntaje/puntaje/store"
import { Asignatura } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "evaluacion-instancia-multiple-resumen",
    templateUrl: "./evaluacion-instancia-multiple-resumen.component.html",
    styleUrls: ["./evaluacion-instancia-multiple-resumen.component.scss"]
})
export class EvaluacionInstanciaMultipleResumenComponent implements OnInit {
    @Input() evaluacionMultipleInstanciaId: number
    @Output() evaluacionMultipleInstanciaReady = new EventEmitter<EvaluacionMultipleInstancia>()
    @Input() enablePercentil: boolean

    @ViewChild("lateralLoadingLayout", { static: true }) lateralLoadingLayout: LoadingLayoutComponent

    evaluacionMultipleInstancia: EvaluacionMultipleInstancia

    correctas: number
    incorrectas: number
    omitidas: number
    dudas: number
    calificacion: number
    percentil: number = 0

    asignaturaIds: number[]
    peorAsignaturaId: number

    asignaturasById$ = this.store.select(selectAsignaturasById)

    constructor(
        protected evaluacionMultipleInstancias: EvaluacionMultipleInstancias,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.lateralLoadingLayout.standby()

        let params = {
            render_options: {
                include: {
                    evaluacion_instancias: {
                        include: {
                            evaluacion: {
                                include: {
                                    instrumento: {
                                        include: {
                                            instrumento_preguntas: null
                                        }
                                    }
                                }
                            }
                        }
                    },
                    evaluacion_multiple: {
                        include: {
                            evaluacion_tipo: null
                        }
                    }
                },
                methods: "total_dudas"
            }
        }

        this.evaluacionMultipleInstancias
            .find(this.evaluacionMultipleInstanciaId, params)
            .then(evaluacionMultipleInstancia => {
                this.evaluacionMultipleInstancia = evaluacionMultipleInstancia

                this.correctas = evaluacionMultipleInstancia.evaluacion_instancias.reduce(
                    (acc, ei) => acc + ei.correctas,
                    0
                )
                this.incorrectas = evaluacionMultipleInstancia.evaluacion_instancias.reduce(
                    (acc, ei) => acc + ei.incorrectas,
                    0
                )
                this.omitidas = evaluacionMultipleInstancia.evaluacion_instancias.reduce(
                    (acc, ei) => acc + ei.omitidas,
                    0
                )
                this.dudas = evaluacionMultipleInstancia.total_dudas
                if (
                    evaluacionMultipleInstancia.evaluacion_multiple.evaluacion_tipo.evaluacion_tipo == "paa" ||
                    evaluacionMultipleInstancia.evaluacion_multiple.evaluacion_tipo.evaluacion_tipo ==
                        "Simulacro Saber Pro"
                ) {
                    this.calificacion = (this.correctas / (this.correctas + this.incorrectas + this.omitidas)) * 100
                } else {
                    this.calificacion = evaluacionMultipleInstancia.evaluacion_instancias.reduce(
                        (acc, ei) => acc + ei.calificacion,
                        0
                    )
                }

                this.asignaturaIds = evaluacionMultipleInstancia.evaluacion_instancias.map(
                    ei => ei.evaluacion.instrumento.asignatura_id
                )
                this.peorAsignaturaId = evaluacionMultipleInstancia.evaluacion_instancias.sort(
                    (a, b) => a.calificacion - b.calificacion
                )[0].evaluacion.instrumento.asignatura_id

                this.evaluacionMultipleInstanciaReady.emit(evaluacionMultipleInstancia)
                this.lateralLoadingLayout.ready()
            })
    }
}
