<loading-layout #loadingLayout>
    <div *ngIf="estadisticaClasificacionesByAsignaturaId">
        <cui-section-headline [role]="'heading'" [ariaLevel]="'3'">Detalle</cui-section-headline>
        <ng-container *ngTemplateOutlet="lecturaYRedaccion; context: evaluacionTipo"></ng-container>
        <div *ngFor="let asignatura of (asignaturasByEvaluacionTipo$ | async)[evaluacionTipo]">
            <div *ngIf="
                    config.plataforma.name == 'College Board' &&
                    asignatura.asignatura != 'Redacción' &&
                    asignatura.asignatura != 'Lectura'
                " class="detalle-asignatura {{ asignatura.clase }}">
                <ogr-icon class="icono" [name]="asignatura.icono"></ogr-icon>
                <div class="detalle">
                    <div class="data-pair">
                        <h4 class="data">
                            {{ asignatura.asignatura }}
                        </h4>
                        <h4 class="value color-primary">
                            {{
                            estadisticaClasificacionesByAsignaturaId
                            | sumTotalPercentage: asignatura.id
                            | roundfloat
                            }}%
                        </h4>
                    </div>

                    <div>
                        <p *ngFor="
                                let estadisticaClasificacion of estadisticaClasificacionesByAsignaturaId[asignatura.id]
                            " class="data-pair">
                            <span class="data">
                                {{ estadisticaClasificacion.clasificacion.clasificacion }}
                            </span>
                            <span class="value">{{ estadisticaClasificacion.porcentaje | roundfloat }}%</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix" *ngIf="enableToggleRespuestas">
            <cui-button class="btn_style pull-right">Ver respuestas de la evaluación</cui-button>
        </div>
    </div>
</loading-layout>

<ng-template #lecturaYRedaccion>
    <div class="detalle-asignatura-less-space lecturaYRedaccion">
        <ogr-icon class="icono" name="lectura-paa"></ogr-icon>
        <div class="detalle">
            <div class="data-pair">
                <h4 class="data">Lectura y Redacción</h4>
                <h4 class="value color-primary">{{ readingAndWritingTotal | roundfloat }}%</h4>
            </div>

            <div *ngFor="let asignatura of (asignaturasByEvaluacionTipo$ | async)[evaluacionTipo]">
                <div *ngIf="
                        config.plataforma.name == 'College Board' &&
                        (asignatura.asignatura == 'Lectura' || asignatura.asignatura == 'Redacción')
                    " class="detalle-asignatura-less-space {{ asignatura.clase }} clear-border-bottom
                    ">
                    <div class="detalle">
                        <div class="data-pair">
                            <span role="heading" aria-level="5" class="subtitle-category data">
                                {{ asignatura.asignatura }}
                            </span>
                            <span class="value subtitle">
                                {{
                                estadisticaClasificacionesByAsignaturaId
                                | sumTotalPercentage: asignatura.id
                                | roundfloat
                                }}%
                            </span>
                        </div>
                        <p *ngFor="
                                let estadisticaClasificacion of estadisticaClasificacionesByAsignaturaId[asignatura.id]
                            " class="data-pair">
                            <span class="data">
                                {{ estadisticaClasificacion.clasificacion.clasificacion }}
                            </span>
                            <span class="value">{{ estadisticaClasificacion.porcentaje | roundfloat }}%</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>