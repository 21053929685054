import { Component, OnInit, Input } from "@angular/core"
import {
    EvaluacionMultiple,
    EvaluacionMultipleInstancia,
    Evaluacion,
    EvaluacionTiempos,
    EvaluacionInstancia
} from "@puntaje/puntaje/api-services"
import { Observable } from "rxjs"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "unlocked-instrumento-cb-row",
    templateUrl: "./unlocked-instrumento-cb-row.component.html",
    styleUrls: ["./unlocked-instrumento-cb-row.component.scss"]
})
export class UnlockedInstrumentoCbRowComponent implements OnInit {
    @Input() evaluacionMultiple: EvaluacionMultiple
    @Input() evaluacionMultipleInstancia: EvaluacionMultipleInstancia
    @Input() evaluacionInstancias: EvaluacionInstancia[]

    porcentajePorAsignatura: any = {}
    evaluacionByAsignaturaId: { [id: number]: Evaluacion } = {}
    evaluacionInstanciaByAsignaturaId: { [id: number]: EvaluacionInstancia } = {}
    evaluacionMultipleDone: boolean = false
    hasBeenCompleted: boolean = false
    evaluaciones: Evaluacion[]

    loading: boolean = true
    @Input() locked: boolean = null

    asignaturasByEvaluacionTipo$: Observable<{ [tipoEvaluacion: string]: AsignaturaWithConfig[] }> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x),
        first()
    )

    constructor(
        protected store: Store<State>,
        protected authService: AuthService,
        protected evaluacionTiemposService: EvaluacionTiempos
    ) {}

    async ngOnInit() {
        this.loading = true

        const usuario = this.authService.getUserData()
        const usuarioId = usuario.id

        const evaluacionInstancias = this.evaluacionMultipleInstancia
            ? this.evaluacionMultipleInstancia.evaluacion_instancias
            : []
        this.evaluaciones = this.evaluacionMultiple.evaluaciones

        this.evaluacionByAsignaturaId = this.evaluaciones.reduce((acc, evaluacion) => {
            acc[evaluacion.instrumento.asignatura_id] = evaluacion

            return acc
        }, {})

        this.evaluacionInstanciaByAsignaturaId = this.evaluaciones.reduce((acc, evaluacion) => {
            acc[evaluacion.instrumento.asignatura_id] = this.evaluacionInstancias.find(
                evaluacionInstancia => evaluacionInstancia.evaluacion_id === evaluacion.id
            )

            return acc
        }, {})

        const filteredEvaluaciones = this.evaluaciones.filter(
            e => !evaluacionInstancias.find(ei => ei.evaluacion_id == e.id)
        )

        this.evaluacionMultipleDone = filteredEvaluaciones.length == 0

        this.evaluacionInstancias.forEach(ei => {
            this.porcentajePorAsignatura[
                ei.evaluacion.instrumento.generador_instrumento.asignatura_plataforma.asignatura_id
            ] = (ei.correctas / (ei.evaluacion.instrumento.numero_preguntas || 1)) * 100
        })

        this.loading = false
    }
}
